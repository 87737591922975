<template>
  <div id="InstitutionalBranches">
    <div class="w">
      <p class="w_p">
        首页 > {{ title }} >
      </p>
      <div class="div_P">
        <p>{{ data.h2 }}</p>
      </div>
      <div class="layout">
        <div class="layout_left">

        </div>
        <div class="layout_right">
          <div class="layout_right_top">
            <div class="layout_right_top_left">
              <div class="ico">
                <div>
                  <img src="../assets/img/branch/username.jpg" alt="" class="username_img">
                </div>
                <div>
                  <p class="username_span">{{ data.username }}</p>
                </div>
              </div>
              <div class="ico">
                <div>
                  <img src="../assets/img/branch/phone.jpg" alt="" class="username_img">
                </div>
                <div>
                  <p class="username_span">{{ data.phone }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="layout_right_top">
            <div class="layout_right_top_left">
              <div class="ico">
                <div>
                  <img src="../assets/img/branch/email.jpg" alt="" class="username_img">
                </div>
                <div>
                  <p class="username_span">{{ data.email }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="layout_right_top">
            <div class="layout_right_top_left">
              <div class="ico">
                <div>
                  <img src="../assets/img/branch/map.jpg" alt="" class="username_img">
                </div>
                <div>
                  <p class="username_span">{{ data.map }}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      title: '机构分支',
      data: { h2: '天津分公司业务', username: '刘润超', phone: '18618489938', email: 'rwinchao@gmail.com', map: '天津市南开区鞍山西道诚信大厦1407' },
      data_Run: { h2: '天津分公司业务', username: '刘润超', phone: '18618489938', email: 'rwinchao@gmail.com', map: '天津市南开区鞍山西道诚信大厦1407' },
      data_bowenberg: { h2: '上海分公司业务负责人', username: '牛屹中', phone: '15222398119', email: 'bowenbergniu@gmail.com', map: '上海市松江区新桥镇莘砖路668号703室' },
      data_Lee: { h2: '北京公司业务经理', username: '李一凡', phone: '18822137116', email: 'fancywebsite.lee@gmail.com', map: '北京市经开区科创12街8号608室' },
      data_Jin: { h2: '纽约办事处负责人', username: '合瑾', phone: '+1 -929-402-6091', email: 'fancywebsite.lee@gmail.com', map: '美国纽约市皇后区凯撒商务公寓A座180室' },
    };
  },
  beforeRouteLeave(to, from, next) {
    console.log('组件内', 'beforeRouteLeave')
    next()
    
    console.log('从哪里来', from)
    this.title = from.meta.title
    console.log('到哪里去', to)
  },
  methods: {
    Date_event(index) {
      console.log(index);
      switch (index) {
        case "0":
          this.data = this.data_Run
          break;
        case "1":
          this.data = this.data_bowenberg
          break;
        case "2":
          this.data = this.data_Lee
          break;
        case "3":
          this.data = this.data_Jin
          break;
      }
    }
  },

  created() {
    let index = this.$route.query.id;
    console.log(index);
    this.Date_event(index)
  },

  mounted() { },

  computed: {},

  watch: {},
};
</script>
<style lang="scss">
//页面内容
@import url('../assets/css/InstitutionalBranches.css');
</style>